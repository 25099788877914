@font-face {
    font-family: 'Monteserrat';
    src: local('MyFont'), url(./fonts/Montserrat-Regular.ttf) format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Monteserrat';
    src: local('MyFont'), url(./fonts/Montserrat-Italic.ttf) format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Monteserrat';
    src: local('MyFont'), url(./fonts/Montserrat-Medium.ttf) format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Monteserrat';
    src: local('MyFont'), url(./fonts/Montserrat-MediumItalic.ttf) format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Monteserrat';
    src: local('MyFont'), url(./fonts/Montserrat-SemiBold.ttf) format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Monteserrat';
    src: local('MyFont'), url(./fonts/Montserrat-SemiBoldItalic.ttf) format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Monteserrat';
    src: local('MyFont'), url(./fonts/Montserrat-Bold.ttf) format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Monteserrat';
    src: local('MyFont'), url(./fonts/Montserrat-BoldItalic.ttf) format('opentype');
    font-weight: 700;
    font-style: italic;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
    background-color: #f0f0f0;
}
.leaflet-popup-content-wrapper {
    background-color: rgba(255, 255, 255, 0.4) !important;
    box-shadow: 0 3px 14px rgba(0,0,0,0.9) !important;
}

.leaflet-popup-tip-container {
    display: none;
}

.leaflet-popup {
    bottom: 45px !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 400px;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image {
    max-height: 400px;
    min-height: 400px;
}

.image-gallery-svg {
    max-height: 60px !important;
}

.image-gallery-left-nav:hover, .image-gallery-right-nav:hover  {
    color: #a78435;
}

.image-gallery-play-button .image-gallery-svg, .image-gallery-fullscreen-button .image-gallery-svg {
    display: none;
}
